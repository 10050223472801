import httpClient from '../../httpClient';
import FullApplicationProgressResponseModel from '../../models/FullApplicationProgressResponseModel';

const ENDPOINT_URL = '/market/app';

export const getApplicationData = (applicationID: number) => {
  return httpClient
    .get(`${ENDPOINT_URL}/${applicationID}`)
    .then((response) => new FullApplicationProgressResponseModel(response))
    .catch((error) => error);
};
