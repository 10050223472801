










































import { defineComponent, ref } from '@vue/composition-api';
import AppSidebar from '../sidebar/AppSidebar.vue';
import AppHeader from '../header/AppHeader.vue';
import BaseText from '../text/BaseText.vue';
import { ZidLoader } from '@zidsa/ui';
import { useGetters as rootGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    AppSidebar,
    AppHeader,
    BaseText,
    ZidLoader,
  },
  setup() {
    const { isAppLoading } = rootGetters(['isAppLoading']);
    const menuOpen = ref(false);
    const toggleMenuOpen = () => (menuOpen.value = !menuOpen.value);

    return {
      menuOpen,
      isAppLoading,
      toggleMenuOpen,
    };
  },
});
