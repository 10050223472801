import store from '../../../store/index';
import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../../routes.enum';

export const useCreateApplicationType = (to: Route, from: Route, next: any) => {
  const applicationType = store.getters['application/appType'];

  switch (to.name) {
    case RoutingRouteEnum.CreateApplication_GeneralSettings_Normal:
      if (applicationType === 'normal') {
        next();
      } else if (applicationType === 'javascript') {
        next({ name: RoutingRouteEnum.CreateApplication_GeneralSettings_Javascript });
      } else next({ name: RoutingRouteEnum.CreateApplication });
      break;
    case RoutingRouteEnum.CreateApplication_GeneralSettings_Javascript:
      if (applicationType === 'javascript') {
        next();
      } else if (applicationType === 'normal') {
        next({ name: RoutingRouteEnum.CreateApplication_GeneralSettings_Normal });
      } else next({ name: RoutingRouteEnum.CreateApplication });
      break;
    default:
      next({ name: RoutingRouteEnum.CreateApplication });
      break;
  }
};
