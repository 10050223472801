












import { defineComponent, PropType, ref } from '@vue/composition-api';
import { FONT_WEIGHTS } from './text-utils/textUtils';
import { TextStylesTypes } from '../../types/components/text/textTypes';
import TextElement from './TextElement.vue';

export default defineComponent({
  name: 'BaseText',
  props: {
    textStyle: {
      type: String as PropType<TextStylesTypes>,
      default: 'p1',
    },
    classes: {
      type: String,
    },
  },
  components: {
    TextElement,
  },
  setup() {
    const font_weights = ref(FONT_WEIGHTS);
    return { font_weights };
  },
});
