import { defineComponent } from '@vue/composition-api';
import AppContainer from './components/app-container/AppContainer.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { isPartnerAuthenticatedLocally, updateGlobalStorageWithLocal } from '@/api/useAuthStatus';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';
import store from './store';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    AppContainer,
  },
  setup() {
    if (isPartnerAuthenticatedLocally()) updateGlobalStorageWithLocal();
    const { isPartnerAuthenticated, role } = useGetters(['isPartnerAuthenticated', 'role']);

    if (isPartnerAuthenticated.value && role.value === null) {
      const { name, email, intercom_hash } = useGetters(['name', 'email', 'intercom_hash']);
      const partnerID = store.getters['partnerID'];
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        user_hash: intercom_hash.value,
        user_id: `${partnerID}`,
        api_base: 'https://api-iam.intercom.io',
        zam_ID: partnerID,
        name: name.value,
        email: email.value,
        language_override: I18nLocaleEnum.en,
      });
    }

    return {
      isPartnerAuthenticated,
    };
  },
});
