import { GetterTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const getters: GetterTree<ApplicationType, any> = {
  isLoadingAppData: (state) => state.loadingAppData,
  createAppProcess: (state) => state.createApp,
  appType: (state) => state.createApp.appType,
  stepsCompleted: (state) => state.createApp.stepsCompletedList,
  inProgressFullApp: (state) => state.createApp.inProgressFullApp,
  appGeneralData: (state) => state.createApp.appGeneralData,
};
