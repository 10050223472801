import BaseIcon from '../../base-icon/BaseIcon.vue';
import DashboardIcon from '../../../assets/icons/sidebar-icons/DashboardIcon.vue';
import PublicAndPrivateAppsIcon from '../../../assets/icons/sidebar-icons/PublicAndPrivateAppsIcon.vue';
import AppsIcon from '../../../assets/icons/sidebar-icons/AppsIcon.vue';
import ShippingIcon from '../../../assets/icons/sidebar-icons/ShippingIcon.vue';
import AdministrationIcon from '../../../assets/icons/sidebar-icons/AdministrationIcon.vue';
import SubscriptionsListIcon from '../../../assets/icons/sidebar-icons/SubscriptionsListIcon.vue';
import DevStoreIcon from '../../../assets/icons/sidebar-icons/DevStoreIcon.vue';
import ApiDocsIcon from '../../../assets/icons/sidebar-icons/ApiDocsIcon.vue';
import ProfileIcon from '../../../assets/icons/sidebar-icons/ProfileIcon.vue';
import MembersIcon from '../../../assets/icons/sidebar-icons/MembersIcon.vue';
import NotificationsIcon from '../../../assets/icons/sidebar-icons/NotificationsIcon.vue';
import HelpIcon from '../../../assets/icons/sidebar-icons/HelpIcon.vue';
import PartnershipIcon from '@/assets/icons/sidebar-icons/PartnershipIcon.vue';
import PartnerManagementIcon from '@/assets/icons/sidebar-icons/PartnerManagementIcon.vue';
import ShoppingCardIcon from '@/assets/icons/sidebar-icons/ShoppingCardIcon.vue';
import ZamWebiteIcon from '@/assets/icons/sidebar-icons/ZamWebiteIcon.vue';
import FaqIcon from '@/assets/icons/sidebar-icons/FaqIcon.vue';
import ThemesIcon from '@/assets/icons/sidebar-icons/ThemesIcon.vue';
import PaymentsIcon from '@/assets/icons/sidebar-icons/PaymentsIcon.vue';
import LogoutIcon from '@/assets/icons/sidebar-icons/LogoutIcon.vue';
import WebhookLogsIcon from '@/assets/icons/sidebar-icons/WebhookLogsIcon.vue';

const useIcons = () => {
  return {
    BaseIcon,
    DashboardIcon,
    AppsIcon,
    ShippingIcon,
    AdministrationIcon,
    PublicAndPrivateAppsIcon,
    PartnerManagementIcon,
    SubscriptionsListIcon,
    DevStoreIcon,
    ApiDocsIcon,
    ProfileIcon,
    MembersIcon,
    NotificationsIcon,
    HelpIcon,
    PartnershipIcon,
    ShoppingCardIcon,
    ZamWebiteIcon,
    FaqIcon,
    ThemesIcon,
    PaymentsIcon,
    LogoutIcon,
    WebhookLogsIcon,
  };
};

export default useIcons;
