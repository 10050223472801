import { ActionTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const actions: ActionTree<ApplicationType, unknown> = {
  setLoadingAppData({ commit }, isLoadingAppData) {
    commit('SET_IS_LOADING_APP_DATA', isLoadingAppData);
  },
  setTypeForApplicationBeingCreated({ commit }, appTypeBeingCreated) {
    commit('SET_TYPE_FOR_APP_BEING_CREATED', appTypeBeingCreated);
  },
  addStepCompleted({ commit }, stepCompleted) {
    commit('ADD_CREATE_APP_STEP_COMPLETED', stepCompleted);
  },
  setInProgressFullApplication({ commit }, fullApplication) {
    commit('SET_CURRENT_IN_PROGRESS_FULL_APP', fullApplication);
  },
  resetCreateAppProcess({ commit }) {
    commit('RESET_CREATE_APP_PROCESS_DATA');
  },
  // application general data
  setAppGeneralData({ commit }, payload) {
    commit('SET_APP_GENERAL_DATA', payload);
  },
  // application general data
};
