export const BOOK_MEETING_URL = 'https://developers.zid.sa/meeting';
export const ALL_ARTICLES_URL = 'https://help-partner.zid.sa/';
export const JOIN_SLACK_URL =
  'https://join.slack.com/t/ziddevworkspace/shared_invite/zt-rrhu52k6-XNIWUUTKMZyFNdEtcXvKTA';
export const CHANGELOG_URL = 'https://changelog.partner.zid.sa/';
export const REQUEST_FEATURE_URL = 'https://changelog.partner.zid.sa/feedback';
export const API_DOCUMENTATION = 'https://docs.zid.sa/';
export const EMBEDDED_APP_DOCS =
  'https://docs.zid.sa/docs/zid-merchant-api/branches/main/cvy6lx6lpys8s-embedded-apps-in-the-merchant-dashboard';
export const API_DOCUMENTATION_REDIRECT_URL =
  'https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization';
export const API_DOCUMENTATION_CALLBACK_URL =
  'https://docs.zid.sa/docs/authorization-migration/2cgihlrgqoz36-authorization';
export const TERMS_OF_USE = 'https://zid.sa/en/terms-conditions/';
// THEMES
export const DEMO_STORE_URL_EXAMPLE = 'https://s3e8s7.zidthemestore.com/';
export const SAMPLE_FEATURE_IMAGE_OF_THEME = 'https://app-market.s3.eu-west-1.amazonaws.com/theme_cover.psd';
export const THEMES_DOCUMENTATION_URL =
  'https://docs.zid.sa/docs/getting-started/kjqpobu5dniq9-getting-started-with-zid-themes';
// ADMIN LINKS
export const METABASE_PRIVATE_APPS_TABLE_WITH_FILTERS = 'https://metabase.zid.sa/question/2635-apps-query';
export const METABASE_APPS_ANALYTICS = 'http://metabase.zid.sa/public/dashboard/2b956a7b-2510-451b-92cd-5b3c6a78c6c8';
export const METABASE_STORE_LIST =
  'https://metabase.zid.sa/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7ImRhdGFiYXNlIjozLCJ0eXBlIjoicXVlcnkiLCJxdWVyeSI6eyJzb3VyY2UtdGFibGUiOjIxODh9fSwiZGlzcGxheSI6InRhYmxlIiwidmlzdWFsaXphdGlvbl9zZXR0aW5ncyI6e319';
// ADMIN LINKS
// FINANCE ADMIN LINKs
export const METABASE_APPS_ANALYTICS_FINANCE =
  'https://metabase.zid.sa/public/dashboard/2b956a7b-2510-451b-92cd-5b3c6a78c6c8'; //<- May be changed
export const METABASE_SHIPPING_ORDERS_FINANCE =
  'https://metabase.zid.sa/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7ImRhdGFiYXNlIjo0LCJ0eXBlIjoicXVlcnkiLCJxdWVyeSI6eyJzb3VyY2UtdGFibGUiOjcxMzh9fSwiZGlzcGxheSI6InRhYmxlIiwidmlzdWFsaXphdGlvbl9zZXR0aW5ncyI6e319';
export const METABASE_SHIPPING_ACTIVATIONS_FINANCE = '';
// FINANCE ADMIN LINKs
// Development Stores links
export const MERCHANT_DASHBOARD_LOGIN_LINK = 'https://web.zid.sa/login';
// Development Stores links
