



























import { defineComponent, reactive, onBeforeMount, watch } from '@vue/composition-api';
import NotificationItem from '../notification-item/NotificationItem.vue';
import NotificationType from '../../types/components/Notification.type';
import { getNotificationsList } from '@/api/top-level-apis/notifications/notifications';
import BaseText from '@/components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { NotificationTypeEnum } from '@/types/components/Notification.type';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  emits: ['updateNewNotificationMark'],
  components: {
    NotificationItem,
    BaseText,
  },
  setup(_, { emit }) {
    const notificationsList = reactive<Record<string, NotificationType[]>>({
      data: [],
    });

    const { name } = useGetters(['name']);

    onBeforeMount(async () => {
      const response = await getNotificationsList();
      if (response instanceof ErrorModel || !response.notes) return;
      notificationsList.data = response.notes;
    });

    watch(
      () => notificationsList,
      (notificationsList) => {
        if (notificationsList.data) {
          const hasUnseenNotifications = notificationsList.data.some((notification) => notification.is_seen === 0);
          emit('updateNewNotificationMark', hasUnseenNotifications);
        }
      },
      { deep: true },
    );

    return {
      name,
      notificationsList,
      NotificationTypeEnum,
    };
  },
});
