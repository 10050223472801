import { Route } from 'vue-router';
import { useResetCreateAppProcess } from '../guards/create-application-type/useResetCreateAppProcess';
import { useAuthentication } from '../guards/authentication/useAuthentication';
import { useAccountCompletionGuard } from '../guards/account-completion-guard';
import { useRedirectToCreateAppStep } from '../guards/create-application-type/useRedirectToCreateAppStep';
import { useRedirectToPartnershipAdmin } from '../guards/partnership-admin/useRedirectToPartnershipAdmin';
import { useResetCreateShippingAppProcess } from '../guards/create-shipping-application/useResetCreateShippingAppProcess';
import { useRedirectToCreateShippingAppStep } from '../guards/create-shipping-application/useRedirectToCreateShippingAppStep';
import { useRedirectToAdmin } from '../guards/pd-admin/useRedirectToAdmin';
import { useRedirectToFinanceAdmin } from '@/router/guards/finance-admin/useRedirectToFinanceAdmin';
import { useAuthorization } from './authorization/useAuthorization';

export const useGlobalGuard = (to: Route, from: Route, next: any) => {
  useAuthentication(to, from, next);
  useAuthorization(to, from, next);
  useAccountCompletionGuard(to, from, next);
  useRedirectToAdmin(to, from, next);
  useRedirectToPartnershipAdmin(to, from, next);
  useResetCreateAppProcess(to, from, next);
  useRedirectToCreateAppStep(to, from, next);
  useResetCreateShippingAppProcess(to, from, next);
  useRedirectToCreateShippingAppStep(to, from, next);
  useRedirectToFinanceAdmin(to, from, next);
};
