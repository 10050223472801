import { MutationTree } from 'vuex';
import { ApplicationType } from './applicationState.type';

export const mutations: MutationTree<ApplicationType> = {
  SET_IS_LOADING_APP_DATA(state, payload) {
    state.loadingAppData = payload;
  },
  SET_TYPE_FOR_APP_BEING_CREATED(state, payload) {
    state.createApp.appType = payload;
  },
  ADD_CREATE_APP_STEP_COMPLETED(state, payload) {
    state.createApp.stepsCompletedList.push(payload);
  },
  // app created, completing steps
  SET_CURRENT_IN_PROGRESS_FULL_APP(state, payload) {
    state.createApp.inProgressFullApp = payload;
  },
  // app created, completing steps
  RESET_CREATE_APP_PROCESS_DATA(state) {
    state.createApp.appType = '';
    state.createApp.stepsCompletedList = [];
    state.createApp.inProgressFullApp = null;
    state.createApp.appGeneralData = null;
  },
  // application general data
  SET_APP_GENERAL_DATA(state, payload) {
    state.createApp.appGeneralData = payload;
  },
  // application general data
};
