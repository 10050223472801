import BaseModel from './BaseModel';

class ApplicationModel extends BaseModel {
  public id: number;
  public partner_id: number;
  public app_category_id: number;
  public type: ApplicationTypeProperty;
  public price: number;
  public language: string;
  public name: string;
  public name_ar: string;
  public contact_email: string;
  public url: string;
  public is_embedded: boolean;
  public redirect_uri: string;
  public client_secret: string | null;
  public client_id: number;
  public listing_status: ApplicationListingStatusProperty | null;
  public icon: string;
  public cover_photo: string | null;
  public screen_shots: string[] | null;
  public video: string | null;
  public description: string | null;
  public description_ar: string | null;
  public short_description: string | null;
  public short_description_ar: string | null;
  public developer_name_ar: string | null;
  public developer_name_en: string | null;
  public steps: number;
  public rate: number;
  public is_listed_in_market: number;
  public is_featured: boolean;
  public featured_until: string | null;
  public is_draft: number;
  public developed_by: string | null;
  public under_review_at: string | null;
  public published_at: string | null;
  public unique_id: string | number;
  public order: number;
  public installation_url: string | null;
  public zid_percentage: string;
  public partner_percentage: string;
  public install_instructions: string | null;
  public is_html_enabled: boolean;
  public scopes_note: string;
  public is_installed: IsAppInstalledType | null;
  public screen_shots_ar: string;
  public screen_shots_en: string;
  public app_url: string;
  public category?: {
    id: number;
    name_ar: string;
    name_en: string;
  };
  public total_app_installed?: number;

  constructor(arg: any) {
    super(arg);
    this.id = arg.id;
    this.partner_id = arg.partner_id;
    this.app_category_id = arg.app_category_id;
    this.type = arg.type;
    this.price = arg.price;
    this.language = arg.language;
    this.name = arg.name;
    this.name_ar = arg.name_ar;
    this.contact_email = arg.contact_email;
    this.url = arg.url;
    this.is_embedded = arg.is_embedded;
    this.redirect_uri = arg.redirect_uri;
    this.client_secret = arg.client_secret;
    this.client_id = arg.client_id;
    this.listing_status = arg.listing_status;
    this.icon = arg.icon;
    this.cover_photo = arg.cover_photo;
    this.screen_shots = arg.screen_shots;
    this.video = arg.video;
    this.description = arg.description;
    this.description_ar = arg.description_ar;
    this.short_description = arg.short_description;
    this.short_description_ar = arg.short_description_ar;
    this.developer_name_ar = arg.developer_name_ar;
    this.developer_name_en = arg.developer_name_en;
    this.steps = arg.steps;
    this.rate = arg.rate;
    this.is_listed_in_market = arg.is_listed_in_market;
    this.is_featured = arg.is_featured;
    this.featured_until = arg.featured_until;
    this.is_draft = arg.is_draft;
    this.developed_by = arg.developed_by;
    this.under_review_at = arg.under_review_at;
    this.published_at = arg.published_at;
    this.unique_id = arg.unique_id;
    this.order = arg.order;
    this.installation_url = arg.installation_url;
    this.zid_percentage = arg.zid_percentage;
    this.partner_percentage = arg.partner_percentage;
    this.install_instructions = arg.install_instructions;
    this.is_html_enabled = arg.is_html_enabled;
    this.scopes_note = arg.scopes_note;
    this.is_installed = arg.is_installed;
    this.screen_shots_ar = arg.screen_shots_ar;
    this.screen_shots_en = arg.screen_shots_en;
    this.app_url = arg.app_url;
    this.category = arg.category;
    this.total_app_installed = arg.total_app_installed;
  }
}

export enum ApplicationTypeProperty {
  OAuth = 0,
  Javascript = 1,
  Shipping = 2,
}

export enum ApplicationListingStatusProperty {
  RequestToPublish = 1,
  Rejected = 2,
  Published = 3,
  Unpublished = 4,
}

export interface IsAppInstalledType {
  app_id: number;
  created_at: string;
  deleted_at: string | null;
  head_scripts_param: string | null;
  id: number;
  store_id: number;
  updated_at: string;
}

export default ApplicationModel;
