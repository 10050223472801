








import { defineComponent, PropType } from '@vue/composition-api';
import { TextStylesTypes } from '../../types/components/text/textTypes';

export default defineComponent({
  props: {
    textStyle: {
      type: String as PropType<TextStylesTypes>,
      default: 'p1',
    },
  },
});
