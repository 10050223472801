export enum RoutingRouteEnum {
  Home = 'home',
  Dashboard = 'dashboard',
  ShippingDashboard = 'shipping-dashboard',
  ThemesDashboard = 'themes-dashboard',
  ThemesManagement = 'themes-management',
  ThemeInfoContainer = 'theme-info-container',
  ThemesPurchaseList = 'themes-purchase-list',
  Subscriptionslist = 'subscriptions-list',
  OrdersManagement = 'orders-management',
  ActivationsManagement = 'activation-management',
  LogIn = 'login',
  Logout = 'logout',
  Register = 'register',
  ForgotPassword = 'forgot-password',
  ChangePassword = 'change-password',
  VerifyEmail = 'verify-email',
  Applications = 'applications',
  CreateApplication = 'create-application',
  CreateApplication_StepsContainer = 'create-application-steps-container',
  CreateApplication_GeneralSettings_Normal = 'create-application-general-settings-normal',
  CreateApplication_GeneralSettings_Javascript = 'create-application-general-settings-javascript',
  CreateApplication_AppDetails = 'create-application-app-details',
  CreateApplication_WebhookManagement = 'create-application-webhook-management',
  CreateApplication_PlansManagement = 'create-application-plans-management',
  CreateApplication_Publish = 'create-application-publish',
  CreateShippingApplication = 'create-shipping-application',
  CreateShippingApplication_StepsContainer = 'create-shipping-application-steps-container',
  CreateShippingApplication_CompanyInfo = 'create-shipping-application-company-info',
  CreateShippingApplication_GeneralSettings = 'create-shipping-application-general-settings',
  CreateShippingApplication_AboutCompany = 'create-shipping-application-about-company',
  CreateShippingApplication_ShippingOptionDetails = 'create-shipping-application-shipping-option-details',
  CreateShippingApplication_WebhookManagement = 'create-shipping-application-webhook-management',
  CreateShippingApplication_Publish = 'create-shipping-application-publish',
  DevelopmentStore = 'development-stores',
  WebhookLogs = 'webhook-logs',
  Help = 'help',
  Notifications = 'notifications',
  BankAccount = 'bank-account',
  Profile = 'profile',
  TeamMembers = 'team-members',
  MemberDetails = 'member-details',
  partnershipDetails = 'partnership-details',
  Payouts = 'payouts',
  NotFound = 'not-found',
  NotAuthorized = 'not-authorized',
  ManagePartnerships_Apps = 'manage-partnerships-apps',
  ManagePartnerships_Themes = 'manage-partnerships-themes',
  ManagePartnerships_PartnerContainer = 'manage-partnerships-partner-container',
  ManagePartnerships_Partner = 'manage-partnerships-partner',
  Admin_AppsManagement = 'admin-apps-management',
  Admin_AppDetailsContainer = 'admin-app-details-container',
  Admin_AppDetails_OAuth = 'admin-app-details-oauth',
  Admin_AppDetails_JS = 'admin-app-details-js',
  Admin_AppDetails_Shipping = 'admin-app-details-shipping',
  Admin_AppDetails_Webhooks = 'admin-app-details-webhooks',
  Admin_PartnersManagement = 'admin-partners-management',
  Admin_PartnerDetails = 'admin-partner-details',
  Admin_SectionsManagement = 'admin-sections-management',
  Admin_AppsCollections = 'admin-apps-collections',
  Admin_ManageCollection = 'admin-manage-collection',
  Admin_SectionDetailsManagement = 'admin-section-details-management',
  Admin_FeaturedApps = 'admin-featured-apps',
  Admin_SuggestedApps = 'admin-suggested-apps',
  Admin_PrivateApps = 'admin-private-apps',
  Admin_RatingsAndReviews = 'admin-ratings-and-reviews',
  Admin_MyApps = 'admin-my-apps',
  Admin_CreateApplication = 'admin-create-application',
  Admin_CreateApplication_StepsContainer = 'admin-create-application-steps-container',
  Admin_CreateApplication_GeneralSettings_Javascript = 'admin-create-application-general-settings-javascript',
  Admin_CreateApplication_AppDetails = 'admin-create-application-app-details',
  Admin_CreateApplication_WebhookManagement = 'admin-create-application-webhook-management',
  Admin_CreateApplication_PlansManagement = 'admin-create-application-plans-management',
  Admin_CreateApplication_Publish = 'admin-create-application-publish',
  Admin_Scripts = 'admin-scripts',
  Admin_ScriptHistory = 'admin-script-history',
  Admin_AppScriptHistory = 'admin-app-script-history',
  Admin_ManagePWCategories = 'admin-manage-pw-categories',
  Admin_ManagePWFAQs = 'admin-manage-pw-faqs',
  FinanceAdmin_AppsSubscriptions = 'finance-admin-apps-subscriptions',
  FinanceAdmin_PartnersManagement = 'finance-admin-partners-management',
  FinanceAdmin_PartnerDetails = 'finance-admin-partner-details',
  FinanceAdmin_ThemesManagement = 'finance-admin-themes-management',
  FinanceAdmin_ShippingOrders = 'finance-admin-shipping-orders',
  FinanceAdmin_ShippingActivations = 'finance-admin-shipping-activations',
  FinanceAdmin_Payouts = 'finance-admin-payouts',
}
