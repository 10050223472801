<template>
  <div
    class="app-header md:shadow-md w-full md:flex flex-row justify-between items-center px-10 py-4 bg-primary-700 md:bg-neutrals-25"
    style="height: 77px"
  >
    <router-link :class="{ invisible: isHelpAndSupportPageOpen }" :to="{ name: RoutingRouteEnum.Help }">
      <div
        class="flex items-center border border-primary-600 text-primary-600 bg-transparent cursor-pointer hover:bg-primary-600 hover:text-white-pureWhite hover:transition-colors duration-300 ease-in-out rounded-md px-3 py-2"
      >
        <base-icon viewBox="0 0 18 17" class="me-2" iconName="help-support">
          <help-icon />
        </base-icon>
        <base-text textStyle="h5"> Help & Support </base-text>
      </div>
    </router-link>
    <div class="mt-3 md:mt-0 flex items-center justify-center md:justify-end">
      <div v-if="includeNotifications" class="me-3 hidden md:block">
        <div @click="toggleShowNotificationsDropdown" class="cursor-pointer">
          <base-icon class="w-7 text-neutrals-600" viewBox="0 0 14 17" iconName="notification-icon">
            <empty-notification-icon />
            <notification-icon v-show="notificationExist" />
          </base-icon>
        </div>
        <div class="absolute top-20 right-6">
          <zid-click-outside @clickOutside="closeNotificationsDropdown">
            <notifications-dropdown
              v-show="showNotifications"
              @updateNewNotificationMark="updateNewNotificationMark"
              @toggleShowNotificationsDropdown="toggleShowNotificationsDropdown"
            />
          </zid-click-outside>
        </div>
      </div>
      <user> </user>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ZidInput, ZidClickOutside } from '@zidsa/ui';
import User from '../user-section/User.vue';
import BaseIcon from '../../components/base-icon/BaseIcon.vue';
import NotificationIcon from '../../assets/icons/notification/NotificationIcon.vue';
import EmptyNotificationIcon from '../../assets/icons/sidebar-icons/NotificationsIcon.vue';
import HelpIcon from '../../assets/icons/sidebar-icons/HelpIcon.vue';
import BaseText from '../../components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import NotificationsDropdown from '@/components/notifications/NotificationsDropdown.vue';
import { PartnerRoleEnum } from '@/types/models/partner.type';
import { RoutingRouteEnum } from '@/router/routes.enum';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    ZidInput,
    User,
    BaseIcon,
    HelpIcon,
    BaseText,
    NotificationIcon,
    EmptyNotificationIcon,
    NotificationsDropdown,
    ZidClickOutside,
  },
  setup(_, ctx) {
    const rolesWithoutNotifications = [PartnerRoleEnum.PartnershipAdmin, PartnerRoleEnum.Finance];
    const notificationExist = ref(false);
    const showNotifications = ref(false);
    const isHelpAndSupportPageOpen = ref(ctx.root.$route.name === RoutingRouteEnum.Help);
    const { accountStepsCompleted, role } = useGetters(['accountStepsCompleted', 'role']);

    watch(
      () => ctx.root.$route,
      () => {
        isHelpAndSupportPageOpen.value = ctx.root.$route.name === RoutingRouteEnum.Help;
      },
      { deep: true },
    );

    const includeNotifications = computed(() => {
      return accountStepsCompleted && rolesWithoutNotifications.indexOf(role.value) === -1;
    });

    const toggleShowNotificationsDropdown = () => {
      showNotifications.value = !showNotifications.value;
    };

    const updateNewNotificationMark = (areNewNotifications) => {
      notificationExist.value = areNewNotifications;
    };

    const closeNotificationsDropdown = () => (showNotifications.value = false);

    return {
      notificationExist,
      showNotifications,
      isHelpAndSupportPageOpen,
      accountStepsCompleted,
      role,
      PartnerRoleEnum,
      RoutingRouteEnum,
      includeNotifications,
      toggleShowNotificationsDropdown,
      updateNewNotificationMark,
      closeNotificationsDropdown,
    };
  },
});
</script>
