import BaseModel from './BaseModel';
import ApiErrorResponseValidation from '../../types/models/apiErrorResponseValidation';

class ErrorModel extends BaseModel {
  public validations: ApiErrorResponseValidation[];

  constructor(arg: any) {
    super(arg);
    if (arg) this.validations = arg.message?.validations;
    else this.validations = [];
  }
}

export default ErrorModel;
